export const navMenus = [
  {
    label: "首页",
    path: "/home",
    subs: [],
  },
  {
    label: "解决方案",
    path: "/solutions",
    subs: [
      {
        label: "企业级账户管理",
        path: "/solutions/accountManagement",
      },
      {
        label: "互联网金融行业",
        path: "/solutions/internetFinance",
      },
      {
        label: "电商行业",
        path: "/solutions/electronicCommerce",
      },
      {
        label: "基金支付",
        path: "/solutions/fundPayment",
      },
    ],
  },
  {
    label: "产品中心",
    path: "/product",
    subs: [
      {
        label: "标准支付产品",
        path: "/product/standardPayment",
      },
      {
        label: "账户中台产品",
        path: "/product/accountHubPlatform",
      },
      {
        label: "账户服务产品",
        path: "/product/accountServices",
      },
    ],
  },
  {
    label: "微博钱包",
    path: "https://wallet.weibopay.com",
    subs: [],
  },
  {
    label: "关于我们",
    path: "/about",
    subs: [],
  },
]

export const mobileNavMenus = [
  {
    label: "首页",
    path: "/mobile",
    subs: [],
  },
  {
    label: "解决方案",
    path: "/solutions",
    subs: [
      {
        label: "企业级账户管理",
        path: "/mobile/accountManagement",
      },
      {
        label: "互联网金融行业",
        path: "/mobile/internetFinance",
      },
      {
        label: "电商行业",
        path: "/mobile/electronicCommerce",
      },
      {
        label: "基金支付",
        path: "/mobile/fundPayment",
      },
    ],
  },
  {
    label: "产品中心",
    path: "/product",
    subs: [
      {
        label: "标准支付产品",
        path: "/mobile/standardPayment",
      },
      {
        label: "账户中台产品",
        path: "/mobile/accountmiddleproduct",
      },
      {
        label: "账户服务产品",
        path: "/mobile/accountserviceproduct",
      },
    ],
  },
  {
    label: "微博钱包",
    path: "https://wallet.weibopay.com",
    subs: [],
  },
  {
    label: "关于我们",
    path: "/mobile/aboutus",
    subs: [],
  },
  {
    label: "新浪数科",
    path: "https://www.sinaft.com/home",
    subs: [],
  },
  {
    label: "新浪基金",
    path: "http://fund.sina.com.cn/fund/web/index",
    subs: [],
  },
]

export const footerSources = [
  {
    label: "新浪数科",
    href: "https://www.sinaft.com/home",
  },
  {
    label: "微博钱包",
    href: "https://wallet.weibopay.com/",
  },
  {
    label: "微博钱包-企业版",
    href: "https://e.pay.sina.com.cn",
  },
  {
    label: "新浪基金",
    href: "http://fund.sina.com.cn/fund/web/index",
  },
]

export const footerCompanyInfos = [
  {
    label: "公司简介",
    href: "/about",
  },
  {
    label: "工作机会",
    href: "/about",
  },
  {
    label: "联系我们",
    href: "/about",
  },
  {
    label: "合作条款",
    href: "/agreement",
  },
  {
    label: "咨询公告",
    href: "/announcement/list",
  },
]

export const strategicPartners = [
  {
    name: "中国银联",
    imgPath: "assets/footer/ZHONGGUOYINLIAN.png",
  },
  {
    name: "网联清算有限公司",
    imgPath: "assets/footer/LIANWANGQINGSUAN.png",
  },
  {
    name: "中国工商银行",
    imgPath: "assets/footer/GONGSHANGBANK.png",
  },
  {
    name: "上海银行",
    imgPath: "assets/footer/SHANGHAIBANK.png",
  },
]

export const footerPartners = [
  {
    src: "footer-ZFYWXKZ.png",
    href: "/footer-ZFYWXKZ.pdf",
  },
  {
    src: "footer-ZFQSXHHYZ.png",
    href: "/ZFQSXHHYZ_L.jpg",
  },
  {
    src: "footer-110.png",
    href: "http://www.cyberpolice.cn/wfjb/",
  },
  {
    src: "footer-pcidss@2x.png",
    href: "/footer-PCI-DSS.pdf",
  },
  {
    src: "footer-cfca@2x.png",
    href: "http://www.cfca.com.cn/",
  },
  {
    src: "footer-ZJGS.png",
    href: "/footer-GJRZ.pdf",
  },
  {
    src: "footer-iso@2x.png",
    href: "https://i2.weibopay.com/cms/img/a970ea0788a7e6abb1e66915c2c48f91.png",
  },
  {
    src: "footer-updss@2x.png",
    href: "/footer-UPDSS.pdf",
  },
]

export const productions = {
  standardPayment: {
    title: "标准支付产品",
    subTitle: "适配主流设备，覆盖各类网络，提供完善的支付服务",
    iconSrc: "assets/home/folder.png",
    iconHoverSrc: "assets/home/folder-hover.png",
    bgSrc: "assets/productCenter/standardPayment/banner-3.jpg",
    path: "/product/standardPayment",
  },
  accountHubPlatform: {
    title: "账户中台产品",
    subTitle: "解决同人不同户的数据隔离痛点，提高集团型企业的会员共享效率",
    iconSrc: "assets/home/person1.png",
    iconHoverSrc: "assets/home/person1-hover.png",
    bgSrc: "assets/productCenter/accountHubPlatform/banner-4.jpg",
    path: "/product/accountHubPlatform",
  },
  accountServices: {
    title: "账户服务产品",
    subTitle: "完善的账户体系，丰富的账户类型，满足全场景的账户功能需求",
    iconSrc: "assets/home/person2.png",
    iconHoverSrc: "assets/home/person2-hover.png",
    bgSrc: "assets/productCenter/accountServices/banner-5.jpg",
    path: "/product/accountServices",
  },
}

export const solutions = {
  accountManagement: {
    title: "企业级账户管理解决方案",
    footerLabel: "企业级账户管理",
    desc: "解决企业的资金管理需求、账户管理需求，将各类型账户嵌入交易场景，让交易和账户的协同更顺畅。\n 提供订单明细文件、账户明细文件、交易电子回单、签章版交易凭证等，满足企业各场景下的交易凭证需求。",
    path: "/solutions/accountManagement",
    bgSrc: "assets/solutions/accountManagement/banner-6.jpg",
    imgSrc: "assets/home/server-banner-1.jpg",
  },
  internetFinance: {
    title: "互联网金融行业解决方案",
    footerLabel: "互联网金融",
    desc: "基于《关于加强商业银行互联网贷款业务管理提升金融服务质效的通知》要求，新浪支付为助贷平台及其合作的资金方提供了一套整体的支付解决方案，实现资金方自主决策并发起支付指令，直接参与贷款资金发放、本息回收代扣、止付等关键环节，在资金流上形成由资金方与客户账户直接对接的资金闭环。",
    path: "/solutions/internetFinance",
    bgSrc: "assets/solutions/internetFinance/banner-7.jpg",
    imgSrc: "assets/home/server-banner-2.jpg",
  },
  electronicCommerce: {
    title: "电商行业解决方案",
    footerLabel: "电商平台",
    desc: "一站式解决资金合规、多样化支付、多角色分账问题，为电商平台提供完善的资金管理解决方案。",
    path: "/solutions/electronicCommerce",
    bgSrc: "assets/solutions/electronicCommerce/banner-8.jpg",
    imgSrc: "assets/home/server-banner-3.jpg",
  },
  fundPayment: {
    title: "基金支付解决方案",
    footerLabel: "基金支付",
    desc: "新浪支付于2014年获得基金销售支付结算资格，在基金支付结算领域具有丰富的经验，全心全意满足您的线上支付结算的需求。",
    path: "/solutions/fundPayment",
    bgSrc: "assets/solutions/fundPayment/banner-9.jpg",
    imgSrc: "assets/home/server-banner-4.jpg",
  },
}

export const homeServes = [
  {
    title: "接入快捷",
    subTitle: "自助式接口文档，专业的技术工程师全程对接支持，响应极速！",
    iconSrc: "assets/home/serve1.png",
  },
  {
    title: "开户省心",
    subTitle:
      "所有材料线上自主提交，后台风控坐席一对一审核，最快10分钟完成入网。",
    iconSrc: "assets/home/serve2.png",
  },
  {
    title: "技术硬核",
    subTitle:
      "采用行业内前沿的技术架构和数字加密安全体系，确保您的资金受到全方位的保护。",
    iconSrc: "assets/home/serve3.png",
  },
  {
    title: "服务完善",
    subTitle:
      "上线后有专业运营客服坐席为您解答使用过程中遇到的疑问，为业务保驾护航。",
    iconSrc: "assets/home/serve4.png",
  },
]

export const homeProgresses = [
  {
    title: "审核签约",
    desc: "在线提交营业执照、身份证、银行账户等基本信息，并按指引完成账户验证。",
    iconSrc: "assets/home/progress-1.png",
  },
  {
    title: "技术对接",
    desc: "针对客户场景提供技术对接支持，专项的技术指导提高对接效率，确保技术实现方案贴近客户真实诉求。",
    iconSrc: "assets/home/progress-2.png",
  },
  {
    title: "开发上线",
    desc: "从接口联调、测试上线到后期系统运维等各方面提供服务，保证成功上线。",
    iconSrc: "assets/home/progress-3.png",
  },
]

export const partners = [
  {
    name: "工商银行",
    eName: "ICBC",
    iconSrc: "assets/home/bank/ICBC.png",
  },
  {
    name: "农业银行",
    eName: "ABC",
    iconSrc: "assets/home/bank/ABC.png",
  },
  {
    name: "中国银行",
    eName: "BOC",
    iconSrc: "assets/home/bank/BOC.png",
  },
  {
    name: "建设银行",
    eName: "CCB",
    iconSrc: "assets/home/bank/CCB.png",
  },
  {
    name: "邮政储蓄银行",
    eName: "PSBC",
    iconSrc: "assets/home/bank/PSBC.png",
  },
  {
    name: "交通银行",
    eName: "Bocom",
    iconSrc: "assets/home/bank/Bocom.png",
  },
  {
    name: "招商银行",
    eName: "CMB",
    iconSrc: "assets/home/bank/CMB.png",
  },
  {
    name: "浦发银行",
    eName: "SPDB",
    iconSrc: "assets/home/bank/SPDB.png",
  },
  {
    name: "光大银行",
    eName: "CEB",
    iconSrc: "assets/home/bank/CEB.png",
  },
  {
    name: "兴业银行",
    eName: "CIB",
    iconSrc: "assets/home/bank/CIB.png",
  },
  {
    name: "平安银行",
    eName: "PAB",
    iconSrc: "assets/home/bank/PAB.png",
  },
  {
    name: "中信银行",
    eName: "CITIC",
    iconSrc: "assets/home/bank/CITIC.png",
  },
  {
    name: "民生银行",
    eName: "CMBC",
    iconSrc: "assets/home/bank/CMBC.png",
  },
  {
    name: "上海银行",
    eName: "BOS",
    iconSrc: "assets/home/bank/BOS.png",
  },
  {
    name: "华夏银行",
    eName: "HXB",
    iconSrc: "assets/home/bank/HXB.png",
  },
  {
    name: "广发银行",
    eName: "CGB",
    iconSrc: "assets/home/bank/CGB.png",
  },
]

export const standardPaymentReceipts = [
  {
    title: "储蓄卡快捷支付",
    desc: "消费者只需提供储蓄卡卡号、户名、手机动态码等卡信息及身份认证信息后，即可完成付款、充值、提现等功能。",
    iconSrc: "assets/productCenter/standardPayment/receipt-1.png",
  },
  {
    title: "信用卡快捷支付",
    desc: "消费者只需提供信用卡卡号、户名、手机动态码等卡信息及身份认证信息后，即可完成付款、充值、提现等功能。",
    iconSrc: "assets/productCenter/standardPayment/receipt-2.png",
  },
  {
    title: "信用卡分期支付",
    desc: "为商家和消费者提供的支付服务，消费者通过信用卡分期付款的方式来购买商家价格较高的产品。",
    iconSrc: "assets/productCenter/standardPayment/receipt-3.png",
  },
  {
    title: "商业委托支付",
    desc: "为收款企业提供的面向企业或个人收取各类款项的产品，帮助收款企业方便、快速地进行收款。",
    iconSrc: "assets/productCenter/standardPayment/receipt-4.png",
  },
  {
    title: "微信/支付宝/云闪付",
    desc: "可提供微信支付、支付宝、云闪付，以及对应的二维码支付、公众号支付、小程序支付。",
    iconSrc: "assets/productCenter/standardPayment/receipt-5.png",
  },
  {
    title: "个人网银支付",
    desc: "支持银行卡支付、新浪支付账户支付、线下支付。",
    iconSrc: "assets/productCenter/standardPayment/receipt-6.png",
  },
  {
    title: "企业网银支付",
    desc: "为企业提供解决企业与企业之间资金往来的服务。",
    iconSrc: "assets/productCenter/standardPayment/receipt-7.png",
  },
]

export const standardPaymentPayments = [
  {
    title: "付款到个人银行卡",
    desc: "新浪支付为个人提供，付款至指定银行账户的资金结算服务。",
    iconSrc: "assets/productCenter/standardPayment/payment-1.png",
  },
  {
    title: "付款到企业银行卡",
    desc: "新浪支付为商家提供，付款至指定银行账户的资金结算服务。",
    iconSrc: "assets/productCenter/standardPayment/payment-2.png",
  },
  {
    title: "付款至新浪账户",
    desc: "新浪支付为商家提供，付款到指定新浪支付账户的资金结算服务。",
    iconSrc: "assets/productCenter/standardPayment/payment-3.png",
  },
]

export const standardPaymentAdvantages = [
  {
    title: "支付产品丰富",
    desc: "一键接入主流支付渠道，满足你丰富的交易场景需求，为你提供全方位的支付体验。",
    iconSrc: "assets/productCenter/standardPayment/advantage-1.png",
  },
  {
    title: "一次接入满足大部分场景需求",
    desc: "支持网站、移动应用接入，可自定义结账流程，打造无缝衔接的客户体验",
    iconSrc: "assets/productCenter/standardPayment/advantage-2.png",
  },
  {
    title: "数据安全，响应及时",
    desc: "采用行业内前沿的技术架构和数字加密安全体系，保障数据安全，及时响应解决问题。",
    iconSrc: "assets/productCenter/standardPayment/advantage-3.png",
  },
]

export const accountHubPlatformAdvantages = [
  {
    title: "降低项目建设成本",
    desc: "共享服务可以极大减少重复功能建设和维护的投资，减少成本。",
    iconSrc: "assets/productCenter/accountHubPlatform/advantage-1.png",
  },
  {
    title: "持续提升项目价值",
    desc: "通过中台制定的统一数据格式及标准，实现数据聚集，为大数据平台提供高质量业务数据，为真正发挥大数据的威力做好储备。",
    iconSrc: "assets/productCenter/accountHubPlatform/advantage-2.png",
  },
  {
    title: "易于项目实施运维",
    desc: "有利于项目实施运维，标准化平台可以减少应用部署复杂度，易于定位故障，隔离故障。",
    iconSrc: "assets/productCenter/accountHubPlatform/advantage-3.png",
  },
  {
    title: "助力业务高效创新",
    desc: "统一中台服务为前台应用提供了丰富、全面的能力支撑，可以实现前台应用快速开发实现，有助于业务创新，并减少试错成本。",
    iconSrc: "assets/productCenter/accountHubPlatform/advantage-4.png",
  },
  {
    title: "增强系统整体稳定性",
    desc: "中台服务的单独开发运维提升了系统的稳定性，前台应用只需要关注业务应用逻辑开发，降低了开发复杂度。",
    iconSrc: "assets/productCenter/accountHubPlatform/advantage-5.png",
  },
  {
    title: "便利实现精准扩展",
    desc: "中台服务减少了前台应用与数据库的连接数量，提升了数据库的支持能力；模块化架构保证了业务的线性快速扩展。",
    iconSrc: "assets/productCenter/accountHubPlatform/advantage-6.png",
  },
]

export const accountServicesAdvantages = [
  {
    title: "资金安全，数据安全",
    desc: "采用行业内前沿的技术架构和数字加密安全体系，确保您的资金、数据受到全方位的保护。",
    iconSrc: "assets/productCenter/accountServices/advantage-1.png",
  },
  {
    title: "用户的全方位识别",
    desc: "实名认证、银行卡三/四要素认证、手机号实名认证、人像比对、身份证照片比对。",
    iconSrc: "assets/productCenter/accountServices/advantage-2.png",
  },
  {
    title: "适用于大多数互联网场景",
    desc: "新浪支付账户服务产品适用于大多数互联网场景，如电商、互联网金融、本地生活、互联网医疗等等。",
    iconSrc: "assets/productCenter/accountServices/advantage-3.png",
  },
]

export const accountHubPlatformMemberPlans = [
  {
    name: "业务主体 1",
    iconSrc: "assets/productCenter/accountHubPlatform/1.png",
  },
  {
    name: "业务主体 2",
    iconSrc: "assets/productCenter/accountHubPlatform/2.png",
  },
  {
    name: "业务主体 3",
    iconSrc: "assets/productCenter/accountHubPlatform/3.png",
  },
  {
    name: "业务主体 4",
    iconSrc: "assets/productCenter/accountHubPlatform/4.png",
  },
]

export const accountServicesSupport = [
  {
    name: "用户钱包余额",
    iconSrc: "assets/productCenter/accountServices/support-1.png",
  },
  {
    name: "商家收入存款",
    iconSrc: "assets/productCenter/accountServices/support-2.png",
  },
  {
    name: "余额支付",
    iconSrc: "assets/productCenter/accountServices/support-3.png",
  },
  {
    name: "账户间转账",
    iconSrc: "assets/productCenter/accountServices/support-4.png",
  },
]

export const accountServicesInfoVerify = [
  {
    name: "实名认证",
    iconSrc: "assets/productCenter/accountServices/user-Info-1.png",
  },
  {
    name: "银行卡三/四要素认证",
    iconSrc: "assets/productCenter/accountServices/user-Info-2.png",
  },
  {
    name: "手机号实名认证",
    iconSrc: "assets/productCenter/accountServices/user-Info-3.png",
  },
  {
    name: "人像比对",
    iconSrc: "assets/productCenter/accountServices/user-Info-4.png",
  },
  {
    name: "身份证照片比对",
    iconSrc: "assets/productCenter/accountServices/user-Info-4.png",
  },
]

export const accountManagementStrategies = [
  {
    title: "自助对账",
    desc: "您可通过平台导出和下载两种方式获取报表数据，完成对账。",
    iconSrc: "assets/solutions/accountManagement/strategy-1.png",
  },
  {
    title: "资金管理",
    desc: "您可随时查看当前账户资金，灵活调配资金，解决资金周转难题。",
    iconSrc: "assets/solutions/accountManagement/strategy-2.png",
  },
  {
    title: "自助结算",
    desc: "您可按需自助将待结算金额提现至账户。",
    iconSrc: "assets/solutions/accountManagement/strategy-3.png",
  },
]

export const accountManagementAuthorizations = [
  {
    title: "审批流程",
    desc: "您可根据公司业务流程，自定义付款、退款内部审批流程。",
    iconSrc: "assets/solutions/accountManagement/authorization-1.png",
    bottomImgSrc: "assets/solutions/accountManagement/authorization-1.jpg",
  },
  {
    title: "子账号功能",
    desc: "您可根据员工职能，设置不同的操作权限，保证数据安全。",
    iconSrc: "assets/solutions/accountManagement/authorization-2.png",
    bottomImgSrc: "assets/solutions/accountManagement/authorization-2.jpg",
  },
]

export const internetFinanceMethods = [
  {
    title: "助贷平台入驻",
    desc: "助贷平台作为新浪支付平台商户入驻",
    iconSrc: "assets/solutions/internetFinance/method-1.png",
  },
  {
    title: "绑卡及签约",
    desc: "用户实名认证，银行卡绑定并签约扣款协议",
    iconSrc: "assets/solutions/internetFinance/method-2.png",
  },
  {
    title: "资金方放款",
    desc: "资金方作为新浪支付金融合作机构入驻，为用户放款",
    iconSrc: "assets/solutions/internetFinance/method-3.png",
  },
]

export const internetFinanceServices = [
  {
    title: "自动扣款",
    desc: "通过清算机构向用户银行卡发起扣款请求",
    iconSrc: "assets/solutions/internetFinance/service-1.png",
  },
  {
    title: "分账待结算账户",
    desc: "扣款成功，资金进入分账待结算账户",
    iconSrc: "assets/solutions/internetFinance/service-2.png",
  },
  {
    title: "还款资金分账",
    desc: "用户还款资金按照平台方及资金方的需求进行分账",
    iconSrc: "assets/solutions/internetFinance/service-3.png",
  },
]

export const internetFinanceProgress = [
  {
    title: "交易手续费延时结算",
    iconSrc: "assets/solutions/internetFinance/progress-1.png",
  },
  {
    title: "支付方式线上化、多样化",
    iconSrc: "assets/solutions/internetFinance/progress-2.png",
  },
  {
    title: "一站式对账及开票",
    iconSrc: "assets/solutions/internetFinance/progress-3.png",
  },
  {
    title: "手续费承担方支持自定义配置",
    iconSrc: "assets/solutions/internetFinance/progress-4.png",
  },
]

export const electronicCommercePainPoints = [
  {
    title: "结算风险",
    desc: "如何保障商户权益，避免提现困难的窘境？",
    iconSrc: "assets/solutions/electronicCommerce/pain-point-1.png",
  },
  {
    title: "合规风险",
    desc: "资金“二清”问题怎么解决，如何合规运营？",
    iconSrc: "assets/solutions/electronicCommerce/pain-point-2.png",
  },
  {
    title: "操作繁杂",
    desc: "线下人工操作流转复杂，工作量大怎么办？",
    iconSrc: "assets/solutions/electronicCommerce/pain-point-3.png",
  },
  {
    title: "财税压力",
    desc: "如何解决财务核账，税务问题如何进行？",
    iconSrc: "assets/solutions/electronicCommerce/pain-point-3.png",
  },
]

export const electronicCommerceAdvantages = [
  {
    title: "隔离资金",
    desc: "规避平台“二清”风险 ",
    iconSrc: "assets/solutions/electronicCommerce/advantage-1.png",
  },
  {
    title: "自动化对账",
    desc: "匹配信息流/资金流/发票流",
    iconSrc: "assets/solutions/electronicCommerce/advantage-2.png",
  },
  {
    title: "自由分账",
    desc: "支持多级分账，金额自由无比例上线",
    iconSrc: "assets/solutions/electronicCommerce/advantage-3.png",
  },
  {
    title: "聚合支付",
    desc: "支持多种支付方式、满足个性化支付需求",
    iconSrc: "assets/solutions/electronicCommerce/advantage-3.png",
  },
  {
    title: "便捷管理",
    desc: "提供可视化的后台管理界面，方便后台财务人员操作",
    iconSrc: "assets/solutions/electronicCommerce/advantage-3.png",
  },
]

export const fundPaymentPainPoints = [
  {
    title: "专用申购、赎回渠道稀缺",
    desc: "基金场景存在金额大、安全性要求高的特点，常规的支付渠道难以满足场景需要",
    iconSrc: "assets/solutions/fundPayment/pain-point-1.png",
  },
  {
    title: "专项解决方案稀缺",
    desc: "基金场景合规性要求高，常规的电商场景支付解决方案难以满足",
    iconSrc: "assets/solutions/fundPayment/pain-point-2.png",
  },
  {
    title: "系统稳定性要求高",
    desc: "互联网基金场景交易量大、并发高，对支付机构及托管行系统稳定性要求高",
    iconSrc: "assets/solutions/fundPayment/pain-point-3.png",
  },
]

export const fundPaymentAdvantages = [
  {
    title: "开户验证绑卡",
    desc: "客户在PC端、移动端（app等）或微信公众号开户时验证身份并完成银行卡绑定 ",
    iconSrc: "assets/solutions/fundPayment/advantage-1.png",
  },
  {
    title: "申购/认购扣款",
    desc: "匹配信息流/提供投资人认购、申购基金、定投交易的扣款服务。",
    iconSrc: "assets/solutions/fundPayment/advantage-2.png",
  },
  {
    title: "赎回/分红付款",
    desc: "提供投资人赎回、分红交易完成后向投资者银行卡账户划款的服务",
    iconSrc: "assets/solutions/fundPayment/advantage-3.png",
  },
  {
    title: "基金支付通道稳定",
    desc: "基金支付通道稳定，申购场景下扣款额度高",
    iconSrc: "assets/solutions/fundPayment/advantage-3.png",
  },
]

export const aboutJoins = [
  {
    title: "我们提供",
    desc: "上市百强公司 + 创业公司 + 第三方支付公司中具有竞争力的薪酬和福利：基本薪资+年终奖，五险一金+补充公积金+餐补，充分信任的上下班弹性工作制。",
  },
  {
    title: "更重要的是",
    desc: "你会在这里获得超出想象的成长空间和价值实现的机会。你的工作将涉及多种与“支付”相关的、具有长远价值的方向和领域，你将获得大量宝贵的技能培训和身处一线的实战经验，你将和国内第三方支付领域的顶尖人才共同奋斗，你将处在移动互联网 + 新兴金融的风口浪尖，你的未来将增值无限。",
  },
  {
    title: "如果想",
    desc: "挑战自己的极限，想趁年轻的时候尽最大可能实现自己的价值，想和第三方支付行业中的一线公司共同体验高速成长所带来的非凡成就感——请加入我们!",
  },
]

export const agreements = [
  {
    // url: '/web/termsDetail/sinapayUserService',
    content: "《新浪支付用户（企业）服务协议》",
    filename: "新浪支付用户（企业）服务协议.pdf",
    isDownload: true,
    fileEncodeName: "SinaPayUser.pdf",
  },
  {
    // url: '/web/termsDetail/sinapayWithhold',
    content: "《新浪支付委托扣款用户服务协议》",
    filename: "新浪支付委托扣款用户服务协议.pdf",
    isDownload: true,
    fileEncodeName: "SinaPayUserWithhold.pdf",
  },
  {
    // url: '/web/termsDetail/sinapayQuickPay',
    content: "《新浪支付快捷支付服务协议》",
    filename: "新浪支付快捷支付服务协议.pdf",
    isDownload: true,
    fileEncodeName: "SinaPayQuickpass.pdf",
  },
  {
    // url: '/web/termsDetail/sinapayPrivacyPolicy',
    content: "《新浪支付个人信息保护政策》",
    filename: "新浪支付个人信息保护政策.pdf",
    isDownload: true,
    fileEncodeName: "SinaUserPravicy.pdf",
  },
  {
    url: "https://page.weibopay.com/agreement/index.html?p=sinapaySpeciallyEngagedRates",
    content: "《新浪支付特约商户收费标准》",
    filename: "《新浪支付特约商户收费标准》.pdf",
    isDownload: false,
    fileEncodeName: "《新浪支付特约商户收费标准》.pdf",
  },
  {
    // url: '/web/termsDetail/sinapayClientService',
    content: "《新浪支付用户服务协议》",
    filename: "新浪支付用户服务协议.pdf",
    isDownload: true,
    fileEncodeName: "sinapayClientService.pdf",
  },
]
